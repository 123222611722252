import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Footer, Header } from "./components/HeaderFooter";

import "./styles/app.scss";

export const App = () => (
  <HelmetProvider>
        <article>
          <Header />

            <div className="container">

            <div className="giving-section hero">
				<div class="hero-content">
              
				  <h1 className="headline">Local Problems Don’t Get Solved Without <nobr>Local Journalism</nobr></h1>

					<div className="action-button-container">
						<a className="action-button" href="https://donorbox.org/citynydonate">Donate securely online</a>
					</div>

            	</div>
            </div>
				
				<p className="copy nut-graf">
				Giving to THE CITY means <em>you</em> are directly  powering our local New York news. Our nonprofit newsroom covers New York's uncovered neighborhoods, holds the powerful to account, and makes sense of the greatest city in the world.
				</p>
				

			<div className="giving-section">
				<h2>
					Monthly Giving
				</h2>

				<p className="copy">
					<strong>By becoming a monthly sustaining donor, you create a steady revenue stream our newsroom can count on.</strong> This lets you turn a small monthly donation into a larger annual one without straining your budget.
					</p>
									
					<div className="action-button-container">
					<a className="action-button" href="https://donorbox.org/thecitysustainers" class="action-link">Become a monthly donor</a>.
					</div>
			</div>

			<div className="giving-section">
				<h2>
					Donor Advised Fund
				</h2>

				<p className="copy">
					<strong>Giving a gift to THE CITY from your Donor Advised Fund (DAF) is a simple yet significant way to support our local NYC journalism.</strong> You can use your DAF to make a one-time or recurring gift.
				</p>
				
				<div className="action-button-container">
					<a className="action-button" href="LINK TK">Give via DAF Direct</a>.
				</div>
				
					<p className="copy">
						<strong>Or, connect with your DAF to direct your gift to:</strong>
					</p>	
					<p className="copy inset">
					City Report, Inc. d/b/a THE CITY EIN Number/Federal Tax <nobr>ID 37-1896785</nobr>. </p>
						<p className="copy inset">
						THE CITY<br />
						ATTN: Development<br />
						85 Broad Street, 12th FL<br />
						New York, NY 10004
					</p>
					
					<p className="copy">
					And ask them to include your name and address on the distribution so we may acknowledge your gift.
					</p>
				
			</div>

			<div className="giving-section">
				<h2>
					Workplace Giving
				</h2>

				<p className="copy">
					<strong>Many companies match employee contributions to charitable organizations, doubling or even tripling the impact of charitable gifts.</strong> Contact your employer’s Human Resources department to see if your company will match your gift to THE CITY. If you would like us to help, please contact us at <a href="mailto:development@thecity.nyc">development@thecity.nyc</a>.
				</p>
			</div>

			<div className="giving-section">
				<h2>
					By Mail
				</h2>

				<p className="copy">
					<strong>To make a donation by mail, please make checks payable to THE CITY and send to:</strong>
				</p>
				<p className="copy inset">
					THE CITY<br />
					ATTN: Development<br />
					85 Broad Street, 12th FL<br />
					New York, NY 10004
				</p>	
			</div>

			<div className="giving-section">
				<h2>
					Questions?
				</h2>	

				<p className="copy">
					We’re here to help! Email us at <a href="mailto:development@thecity.nyc">development@thecity.nyc</a> or call <a href="tel:201-580-6355">(201) 580-6355</a>.
				</p>

				<p className="copy">
					City Report, Inc. d/b/a THE CITY is recognized as tax-exempt under section 501(c)(3) of the Internal Revenue Code, EIN Number/Federal Tax <nobr>ID 37-1896785</nobr>. 
				</p>

				<div>
					<a href="https://www.guidestar.org/profile/37-1896785" ><img src="https://widgets.guidestar.org/TransparencySeal/9859781" alt="Candid Platinum Transparancy seal" /></a>
				</div>
			</div>

            </div>
            <Footer />
          </article>
  </HelmetProvider>
);
